import React from "react";
import { Text, View, Image, StyleSheet } from "react-native-web";
import guardian from "../assets/screenshots/guardian.png";
import fighter from "../assets/screenshots/fighter.png";
import { Link } from "react-router-dom";

//browser windows height
const height = window.innerHeight;

const CreateAccount = () => {
  return (
    <View
      style={{
        width: "100%",
        height: height,
        alignItems: "center",
        backgroundColor: "#434343",
      }}
    >
      <View style={styles.container}>
        <View>
          <div className="Lexend" style={styles.title}>
            Account Creation
          </div>
        </View>
        <View
          style={[
            styles.logoContainer,
            {
              maxWidth: "100%",
              maxHeight: "50%",
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            },
          ]}
        >
          <View
            style={{
              width: "33%",
              height: "100%",
              marginRight: 10,
              marginLeft: 10,
              alignItems: "center",
            }}
          >
            <Image source={fighter} style={styles.image} />
          </View>
          <View
            style={{
              width: "35%",
              height: "100%",
              alignItems: "center",
              paddingLeft: 20,
              marginLeft: 10,
            }}
          >
            <Text
              style={{
                fontFamily: "Lexend",
                fontSize: 30,
                justifyContent: "center",
                textAlign: "center",
                color: "#fff",
              }}
            >
              Users can select from either a fighter or guardian account type.
              The fighter account type is for users who wish to participate in
              classes themselves, while the guardian account type is for users
              who wish to manage the accounts and class registrations for
              juniors in their care.
            </Text>
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                minHeight: 100,
                minWidth: "100%",
                paddingTop: 50,
              }}
            >
              <View
                style={{
                  backgroundColor: "#1c4587",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 30,
                    fontFace: "Lexend",
                    color: "#fff",
                    textAlign: "center",
                    marginBottom: 10,
                    marginRight: 15,
                  }}
                >
                  For full instructions click
                </Text>
              </View>
              <View
                style={{
                  backgroundColor: "#fff",
                  justifyContent: "center",
                  borderRadius: 10,
                }}
              >
                <Link to="/instructions" style={{ textDecoration: "none" }}>
                  <Text
                    style={{
                      fontFamily: "Lexend",
                      fontSize: 30,
                      paddingHorizontal: 10,
                    }}
                  >
                    Here
                  </Text>
                </Link>
              </View>
            </View>
          </View>
          <View
            style={{
              width: "33%",
              height: "100%",
              marginLeft: 10,
              alignItems: "center",
            }}
          >
            <Image source={guardian} style={styles.image} />
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "82%",
    height: "100%",
    marginTop: 50,
    alignItems: "center",
    backgroundColor: "#1c4587", // Background color from the logo
    // Shadow for iOS
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 10 },
    shadowOpacity: 0.3,
    shadowRadius: 20,
    // Elevation for Android and Web
    elevation: 10,
    transform: [{ translateY: -50 }],
  },
  logoContainer: {
    marginTop: 100,
    marginBottom: 20,
  },
  image: {
    width: "100%",
    height: "100%",
    marginHorizontal: 20,
    resizeMode: "contain",
  },
  title: {
    FontFace: "Lexend",
    fontSize: 100,
    fontWeight: 1000,
    color: "#fff",
  },
  subtitle: {
    fontSize: 16,
    color: "white",
  },
});

export default CreateAccount;
