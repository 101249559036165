import React from "react";
import { View, Image, Text, StyleSheet } from "react-native-web";
import logo from "../assets/home-banner.png";

//browser windows height
const height = window.innerHeight;

const TitleSlide = () => {
  return (
    <View
      style={{
        width: "100%",
        height: height,
        alignItems: "center",
        backgroundColor: "#434343",
      }}
    >
      <View style={styles.container}>
        <View style={styles.logoContainer}>
          <Image source={logo} style={styles.logo} />
        </View>
        <Text style={styles.title}>Welcome to Mat Space</Text>
        <Text style={styles.subtitle}>By Stripe 5</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "82%",
    height: "100%",
    marginTop: 50,
    alignItems: "center",
    backgroundColor: "#1C4587", // Background color from the logo
    // Shadow for iOS
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 10 },
    shadowOpacity: 0.3,
    shadowRadius: 20,
    // Elevation for Android and Web
    elevation: 10,
    transform: [{ translateY: -50 }],
  },
  logoContainer: {
    marginTop: 100,
    marginBottom: 20,
  },
  logo: {
    width: 960,
    height: 540,
    resizeMode: "contain",
  },
  title: {
    fontSize: 24,
    color: "white",
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 16,
    color: "white",
  },
});

export default TitleSlide;
