import React from "react";
import { Text, View, Image, StyleSheet } from "react-native-web";
import profile from "../../assets/screenshots/flows/createAccount/myProfile.png";
import editProfile from "../../assets/screenshots/flows/createAccount/editProfile.png";
import confirmDelete from "../../assets/screenshots/flows/createAccount/confirmDelete.png";

//browser windows height
const height = window.innerHeight;

const DeleteScreens = () => {
  return (
    <View
      style={{
        width: "100%",
        height: height,
        alignItems: "center",
        backgroundColor: "#434343",
      }}
    >
      <View style={styles.container}>
        <View>
          <Text style={styles.title}>Delete Screens</Text>
        </View>

        <View
          style={[
            styles.logoContainer,
            {
              maxWidth: "100%",
              minWidth: "100%",
              maxHeight: "50%",
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            },
          ]}
        >
          <View
            style={{
              width: "30%",
              height: "100%",
              marginRight: 10,
              marginLeft: 10,
              alignItems: "center",
            }}
          >
            <Image source={profile} style={styles.image} />
            <Text style={styles.subtitle}>Tap the edit profile button</Text>
          </View>
          <View
            style={{
              width: "30%",
              height: "100%",
              marginRight: 10,
              marginLeft: 10,
              alignItems: "center",
            }}
          >
            <Image source={editProfile} style={styles.image} />
            <Text style={styles.subtitle}>Then the "DELETE" button</Text>
          </View>
          <View
            style={{
              width: "30%",
              height: "100%",
              marginRight: 10,
              marginLeft: 10,
              alignItems: "center",
            }}
          >
            <Image source={confirmDelete} style={styles.image} />
            <Text style={styles.subtitle}>And finally , tap "confirm"</Text>
          </View>
        </View>
        <Text style={[styles.subtitle, { marginTop: 75 }]}>
          Do be aware. Deleting your account will automatically cancel any
          active subscriptions!!
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "82%",
    height: "100%",
    marginTop: 50,
    alignItems: "center",
    backgroundColor: "#a21111", // Background color from the logo
    // Shadow for iOS
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 10 },
    shadowOpacity: 0.3,
    shadowRadius: 20,
    // Elevation for Android and Web
    elevation: 10,
    transform: [{ translateY: -50 }],
  },
  logoContainer: {
    marginTop: 50,
    marginBottom: 20,
  },
  image: {
    width: "100%",
    height: "100%",
    marginHorizontal: 20,
    resizeMode: "contain",
  },
  title: {
    fontFace: "Lexend",
    fontSize: 100,
    fontWeight: 1000,
    color: "#fff",
    textAlign: "center",
  },
  subtitle: { fontFamily: "Lexend", fontSize: 22, color: "#fff" },
});

export default DeleteScreens;
