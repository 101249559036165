import React, { useRef, useEffect } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import SelectCreateAccount from "./accountFlow/SelectCreateAccount";
import AccountTypes from "./accountFlow/AccountTypes";
import BackToTop from "../components/BackToTop";
import EmailSlide from "./accountFlow/EmailSlide";
import PasswordSlide from "./accountFlow/PasswordSlide";
import PersonalDetails from "./accountFlow/PersonalDetails";
import AgeClasses from "./accountFlow/AgeClasses";
import Summary from "./accountFlow/Summary";
import Deletion from "./accountFlow/Deletion";
import DeleteScreens from "./accountFlow/DeleteScreens";

const slideHeight = window.innerHeight;

const AccountSlides = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleScroll = (event) => {
    const container = containerRef.current;
    if (!container) return;

    const sectionHeight = window.innerHeight; // Adjust this value as needed
    const scrollTop = event.nativeEvent.contentOffset.y;
    const targetScroll = Math.round(scrollTop / sectionHeight) * sectionHeight;

    container.scrollTo({
      y: targetScroll,
      animated: true,
    });
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const slides = [
    <SelectCreateAccount />,
    <AccountTypes />,
    <EmailSlide />,
    <PasswordSlide />,
    <PersonalDetails />,
    <AgeClasses />,
    <Summary />,
    <Deletion />,
    <DeleteScreens />,
  ];

  return (
    <View>
      <ScrollView
        style={styles.container}
        ref={containerRef}
        scrollEventThrottle={16}
        onScrollEndDrag={handleScroll}
        onMomentumScrollEnd={handleScroll}
      >
        {slides.map((slide, index) => (
          <View
            key={index}
            style={[styles.slide, { backgroundColor: "#454545" }]}
          >
            {slide}
          </View>
        ))}
      </ScrollView>
      <BackToTop numberOfSlides={slides.length} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  slide: {
    height: slideHeight,
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontSize: 24,
    color: "white",
  },
});

export default AccountSlides;
