import React from "react";
import { Text, View, Image, StyleSheet } from "react-native-web";
import loginImg from "../../assets/screenshots/flows/createAccount/login.png";
import { Link } from "react-router-dom";

//browser windows height
const height = window.innerHeight;

const SelectCreateAccount = () => {
  return (
    <View
      style={{
        width: "100%",
        height: height,
        alignItems: "center",
        backgroundColor: "#434343",
      }}
    >
      <View style={styles.container}>
        <View
          style={{
            width: "100%",
            alignItems: "flex-start",
          }}
        >
          <Link to="/" style={{ textDecoration: "underline" }}>
            <Text
              style={{
                fontFamily: "Lexend",
                fontSize: 30,
                paddingHorizontal: 10,
                color: "#fff",
                fontWeight: 500,
                textAlign: "left",
              }}
            >
              {`< Home`}
            </Text>
          </Link>
        </View>
        <View>
          <Text style={styles.title}>Account Creation</Text>
          <Text style={[styles.title, { marginTop: -40, marginBottom: 25 }]}>
            Instructions
          </Text>
        </View>
        <Text
          style={{
            fontFamily: "Lexend",
            fontSize: 30,
            justifyContent: "center",
            textAlign: "center",
            color: "#fff",
          }}
        >
          Note: Your gym must first be signed up to use the mat-space gym
          management system.
        </Text>
        <View
          style={[
            styles.logoContainer,
            {
              maxWidth: "100%",
              maxHeight: "50%",
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            },
          ]}
        >
          <View
            style={{
              width: "33%",
              height: "100%",
              marginRight: 10,
              marginLeft: 10,
              alignItems: "center",
            }}
          >
            <Image source={loginImg} style={styles.image} />
          </View>
          <View
            style={{
              width: "35%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: 20,
              marginLeft: 10,
            }}
          >
            <Text
              style={{
                fontFamily: "Lexend",
                fontSize: 30,
                justifyContent: "center",
                textAlign: "center",
                color: "#fff",
              }}
            >
              First: Tap the "Create Account" button on the login screen.
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "82%",
    height: "100%",
    marginTop: 50,
    alignItems: "center",
    backgroundColor: "#a21111", // Background color from the logo
    // Shadow for iOS
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 10 },
    shadowOpacity: 0.3,
    shadowRadius: 20,
    // Elevation for Android and Web
    elevation: 10,
    transform: [{ translateY: -50 }],
  },
  logoContainer: {
    marginTop: 50,
    marginBottom: 20,
  },
  image: {
    width: "100%",
    height: "100%",
    marginHorizontal: 20,
    resizeMode: "contain",
  },
  title: {
    fontFace: "Lexend",
    fontSize: 100,
    fontWeight: 1000,
    color: "#fff",
    textAlign: "center",
  },
  subtitle: {
    fontSize: 16,
    color: "white",
  },
});

export default SelectCreateAccount;
