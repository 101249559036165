import React, { useRef, useEffect } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import TheApp from "./TheApp";
import CreateAccount from "./CreateAccount";
import Timetable from "./Timetable";
import TitleSlide from "./TitleSlide";

const slideHeight = window.innerHeight;

const HomeSlides = () => {
  const containerRef = useRef(null);

  const handleScroll = (event) => {
    const container = containerRef.current;
    if (!container) return;

    const sectionHeight = window.innerHeight; // Adjust this value as needed
    const scrollTop = event.nativeEvent.contentOffset.y;
    const targetScroll = Math.round(scrollTop / sectionHeight) * sectionHeight;

    container.scrollTo({
      y: targetScroll,
      animated: true,
    });
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <ScrollView
      style={styles.container}
      ref={containerRef}
      scrollEventThrottle={16}
      onScrollEndDrag={handleScroll}
      onMomentumScrollEnd={handleScroll}
    >
      <View style={[styles.slide, { backgroundColor: "#454545" }]}>
        <TitleSlide />
      </View>
      <View style={[styles.slide, { backgroundColor: "#454545" }]}>
        <TheApp />
      </View>
      <View style={[styles.slide, { backgroundColor: "#454545" }]}>
        <CreateAccount />
      </View>
      <View style={[styles.slide, { backgroundColor: "#454545" }]}>
        <Timetable />
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  slide: {
    height: slideHeight,
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontSize: 24,
    color: "white",
  },
});

export default HomeSlides;
