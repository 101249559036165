import React, { useCallback, useEffect, useState } from "react";
import "./backToTop.css";
import { TouchableOpacity } from "react-native-web";

const BackToTop = (props) => {
  const { numberOfSlides } = props;
  const handleButtonClick = () => {
    // Logic to scroll to the top of the page
    window.scrollTo(0, 0);
  };

  const [invert, setInvert] = useState(false);

  const handleScroll = useCallback(() => {
    const scrollY = window.scrollY;

    const heightRanges = [];
    for (let i = 0; i < numberOfSlides; i++) {
      heightRanges.push(i * window.innerHeight);
    }

    let locationIndex = 0;
    for (let i = 0; i < heightRanges.length; i++) {
      if (scrollY + window.innerHeight > heightRanges[i]) {
        locationIndex = i;
      }
    }

    const shouldInvert = locationIndex % 2 === 0;
    console.log("shouldInvert", shouldInvert, "locationIndex", locationIndex);

    // Example condition to toggle invert (You can improve this condition based on your needs)
    if (shouldInvert) {
      setInvert(true);
    } else {
      setInvert(false);
    }
  }, [numberOfSlides]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <div
      className={`FixedComponent ${invert ? "invert" : ""}`}
      style={{ right: "10.3%" }}
    >
      <TouchableOpacity onPress={handleButtonClick}>
        <p className="FixedText">Back to Top</p>
      </TouchableOpacity>
    </div>
  );
};

export default BackToTop;
