import React from "react";
import { Text, View, Image, StyleSheet } from "react-native-web";
import guardian from "../../assets/screenshots/guardian.png";
import fighter from "../../assets/screenshots/fighter.png";

//browser windows height
const height = window.innerHeight;

const AccountTypes = () => {
  return (
    <View
      style={{
        width: "100%",
        height: height,
        alignItems: "center",
        backgroundColor: "#454545",
      }}
    >
      <View style={styles.container}>
        <View>
          <div className="Lexend" style={styles.title}>
            Account Types
          </div>
        </View>
        <Text
          style={{
            fontFamily: "Lexend",
            fontSize: 30,
            justifyContent: "center",
            textAlign: "center",
            marginTop: 20,
            color: "#a21111",
          }}
        >
          Now select the type of account you would like to create.
        </Text>
        <View
          style={[
            styles.logoContainer,
            {
              maxWidth: "100%",
              maxHeight: "50%",
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            },
          ]}
        >
          <View
            style={{
              width: "33%",
              height: "100%",
              marginRight: 10,
              marginLeft: 10,
              alignItems: "center",
            }}
          >
            <Image source={fighter} style={styles.image} />
          </View>
          <View
            style={{
              width: "35%",
              height: "100%",
              alignItems: "center",
              paddingLeft: 20,
              marginLeft: 10,
            }}
          >
            <Text
              style={{
                fontFamily: "Lexend",
                fontSize: 30,
                justifyContent: "center",
                textAlign: "center",
                color: "#a11",
              }}
            >
              Swipe left or right for fighter or guardian. Then simply tap the
              image to select.
            </Text>
          </View>
          <View
            style={{
              width: "33%",
              height: "100%",
              marginLeft: 10,
              alignItems: "center",
            }}
          >
            <Image source={guardian} style={styles.image} />
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "82%",
    height: "100%",
    marginTop: 50,
    alignItems: "center",
    backgroundColor: "#fff", // Background color from the logo
    // Shadow for iOS
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 10 },
    shadowOpacity: 0.3,
    shadowRadius: 20,
    // Elevation for Android and Web
    elevation: 10,
    transform: [{ translateY: -50 }],
  },
  logoContainer: {
    marginTop: 50,
    marginBottom: 20,
  },
  image: {
    width: "100%",
    height: "100%",
    marginHorizontal: 20,
    resizeMode: "contain",
  },
  title: {
    FontFace: "Lexend",
    fontSize: 100,
    fontWeight: 1000,
    color: "#a21111",
    textAlign: "center",
  },
  subtitle: {
    fontSize: 16,
    color: "white",
  },
});

export default AccountTypes;
