import React from "react";
import { Text, View, Image, StyleSheet } from "react-native-web";
import ageCategories from "../../assets/screenshots/flows/createAccount/ageCategories.png";

//browser windows height
const height = window.innerHeight;

const AgeClasses = () => {
  return (
    <View
      style={{
        width: "100%",
        height: height,
        alignItems: "center",
        backgroundColor: "#454545",
      }}
    >
      <View style={styles.container}>
        <View>
          <div className="Lexend" style={styles.title}>
            Age Classes
          </div>
        </View>
        <View
          style={[
            styles.logoContainer,
            {
              maxWidth: "100%",
              maxHeight: "50%",
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            },
          ]}
        >
          <View
            style={{
              width: "33%",
              height: "100%",
              marginRight: 10,
              marginLeft: 10,
              alignItems: "center",
            }}
          >
            <Image source={ageCategories} style={styles.image} />
          </View>
          <View
            style={{
              width: "50%",
              height: "100%",
              paddingLeft: 20,
              marginLeft: 10,
            }}
          >
            <Text
              style={{
                marginTop: 55,
                fontFamily: "Lexend",
                fontSize: 25,
                justifyContent: "center",
                textAlign: "center",
                color: "#a11",
              }}
            >
              Competition age classes are broken up as follows:
            </Text>
            <ul>
              <li style={styles.listItem}>Juniors: -18</li>
              <li style={styles.listItem}>Adult: 18-29</li>
              <li style={styles.listItem}>Master 1: 30-35</li>
              <li style={styles.listItem}>Master 2: 36-40</li>
              <li style={styles.listItem}>Master 3: 41-45</li>
              <li style={styles.listItem}>Master 4: 46-50</li>
              <li style={styles.listItem}>Master 5: 51-55</li>
              <li style={styles.listItem}>Master 6: 56-60</li>
              <li style={styles.listItem}>Master 7: 61 +</li>
            </ul>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "82%",
    height: "100%",
    marginTop: 50,
    alignItems: "center",
    backgroundColor: "#fff", // Background color from the logo
    // Shadow for iOS
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 10 },
    shadowOpacity: 0.3,
    shadowRadius: 20,
    // Elevation for Android and Web
    elevation: 10,
    transform: [{ translateY: -50 }],
  },
  logoContainer: {
    marginTop: 50,
    marginBottom: 20,
  },
  image: {
    width: "100%",
    height: "100%",
    marginHorizontal: 20,
    resizeMode: "contain",
  },
  title: {
    FontFace: "Lexend",
    fontSize: 100,
    fontWeight: 1000,
    color: "#a21111",
    textAlign: "center",
  },
  subtitle: {
    fontSize: 16,
    color: "white",
  },
  listItem: {
    fontFamily: "Lexend",
    color: "#a22",
    fontSize: 20,
    textAlign: "left",
  },
});

export default AgeClasses;
