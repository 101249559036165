import React from "react";
import { Text, View, Image, StyleSheet } from "react-native-web";
import dayClasses from "../assets/screenshots/dayClasses.png";
import classView from "../assets/screenshots/classView.png";

//browser windows height
const height = window.innerHeight;

const Timetable = () => {
  return (
    <View
      style={{
        width: "100%",
        height: height,
        paddingTop: 5,
        alignItems: "center",
        backgroundColor: "#434343",
      }}
    >
      <View style={styles.container}>
        <View>
          <div className="Lexend" style={styles.title}>
            The Timetable
          </div>
        </View>
        <View
          style={[
            styles.logoContainer,
            {
              maxWidth: "100%",
              maxHeight: "50%",
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            },
          ]}
        >
          <View
            style={{
              width: "33%",
              height: "100%",
              marginRight: 10,
              marginLeft: 10,
              alignItems: "center",
            }}
          >
            <Image source={dayClasses} style={styles.image} />
          </View>
          <View
            style={{
              width: "33%",
              height: "100%",
              alignItems: "center",
              paddingLeft: 20,
              marginLeft: 10,
            }}
          >
            <Text
              style={{
                fontFamily: "Lexend",
                fontSize: 30,
                justifyContent: "center",
                textAlign: "center",
                color: "#1c4587",
              }}
            >
              Users can view their gyms timetable using month selector and day
              picker. Then select the class you wish to attend and sign up.
              Should life get in the way, users can bow out of the class.
            </Text>
          </View>
          <View
            style={{
              width: "33%",
              height: "100%",
              marginLeft: 10,
              alignItems: "center",
            }}
          >
            <Image source={classView} style={styles.image} />
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "82%",
    height: "100%",
    marginTop: 50,
    alignItems: "center",
    backgroundColor: "#fff", // Background color from the logo
    // Shadow for iOS
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 10 },
    shadowOpacity: 0.3,
    shadowRadius: 20,
    // Elevation for Android and Web
    elevation: 10,
    transform: [{ translateY: -50 }],
  },
  logoContainer: {
    marginTop: 100,
    marginBottom: 20,
  },
  image: {
    width: "100%",
    height: "100%",
    marginHorizontal: 20,
    resizeMode: "contain",
  },
  title: {
    FontFace: "Lexend",
    fontSize: 100,
    fontWeight: 1000,
    color: "#1C4587",
  },
  subtitle: {
    fontSize: 16,
    color: "white",
  },
});

export default Timetable;
