import React from "react";
import { Link } from "react-router-dom";
import { View, Image, Text, StyleSheet } from "react-native-web";
import logo from "../../assets/msicon.jpg";

//browser windows height
const height = window.innerHeight;

const Error404 = () => {
  return (
    <View
      style={{
        width: "100%",
        height: height,
        alignItems: "center",
        backgroundColor: "#434343",
      }}
    >
      <View style={styles.container}>
        <Text
          style={{
            paddingTop: 100,
            fontFamily: "Lexend",
            color: "#fff",
            fontWeight: 800,
            fontSize: 75,
          }}
        >
          Error 404
        </Text>
        <View style={styles.logoContainer}>
          <Image source={logo} style={styles.logo} />
        </View>
        <Text style={styles.title}>Error 404 - Page not found</Text>
        <Text style={styles.subtitle}>
          You've gound out of bands, please return to center!
        </Text>

        <Link to="/" style={{ textDecoration: "none" }}>
          <View
            style={{
              backgroundColor: "#fff",
              padding: 15,
              borderRadius: 15,
              marginTop: 15,
            }}
          >
            <Text
              style={{ fontFamily: "Lexend", color: "#1c4587", fontSize: 30 }}
            >
              Home
            </Text>
          </View>
        </Link>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "82%",
    height: "100%",
    marginTop: 50,
    alignItems: "center",
    backgroundColor: "#1C4587", // Background color from the logo
    // Shadow for iOS
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 10 },
    shadowOpacity: 0.3,
    shadowRadius: 20,
    // Elevation for Android and Web
    elevation: 10,
    transform: [{ translateY: -50 }],
  },
  logoContainer: {
    marginBottom: 20,
  },
  logo: {
    width: 800,
    height: 400,
    resizeMode: "contain",
  },
  title: {
    fontSize: 24,
    color: "white",
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 16,
    color: "white",
  },
});

export default Error404;
